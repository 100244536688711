<template>
  <section id="add-video-muro-digital">
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 my-3">
          Selecciona los videos e imágenes que quieres añadir a la lista de
          reproducción.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <label for="video-load" class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50"
          v-show="video_data.value != 1" @click="addImageModal">
          <input type="file" id="video-load" :disabled="video_data.value == 2 || video_data.value == 3"
            @change="onFileLoad($event)" :accept="textComputed.accept" style="display: none" />
          <font-awesome-icon icon="plus" class="pe-2" />
          {{ textComputed.button }}
        </label>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 col-lg-6 input-search">
        <input type="search" class="form-control input-custom" :placeholder="
            video_data.type == 'video'
              ? 'Ingresa nombre del video'
              : 'Ingresa nombre de la imagen'
          " v-model="title" @keypress.enter="onVideoSearch()" />
        <span class="input-search-icon">
          <font-awesome-icon icon="search" />
        </span>
      </div>
      <div class="col-12 col-lg-4 offset-lg-2 d-flex align-items-center justify-content-end mt-3 mt-lg-0">
        <div class="d-flex align-items-center w-100">
          <p class="mb-0 me-2">Desde</p>
          <v-select class="selvue-custom w-100" :options="fillter_opts" placeholder="Selecciona" label="text"
            v-model="video_data" :clearable="false" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div id="card-container" class="card card-custom border-round-10 card-shadow border-0">
          <div class="card-body">
            <div class="row mb-3">
              <div class="co-12 col-lg-8">
                <h5 class="card-title font-title-bold color-main">
                  {{ textComputed.title_1 }}
                </h5>
                <p class="text-secondary mb-0">
                  Posiciónate sobre
                  <span>{{ textComputed.title_2 }}</span>
                  y selecciona entre previsualizar, añadir o eliminar.
                </p>
              </div>
              <div v-show="video_data.value == 1" class="col-12 col-lg-4">
                <v-select class="selvue-custom" :options="categorias" placeholder="Selecciona una categoría"
                  label="nombre_categoria" v-model="cat_selected" :clearable="false">
                </v-select>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <template v-if="video_data.type == 'video'">
                  <VideosContainer :videos="list_video" :pagination="pagination" @videoDeleted="onDeleteListChange"
                    @playlistChange="onPlaylistChange" />
                </template>
                <template v-else-if="video_data.type == 'externo'">
                  <VideosYTContainer :videos="list_video" :pagination="pagination" @videoDeleted="onDeleteListChange"
                    @playlistChange="onPlaylistChange"></VideosYTContainer>
                </template>
                <template v-if="video_data.type == 'image'">
                  <ImagenesContainer :images="list_video" :pagination="pagination" @playlistChange="onPlaylistChange"
                    @imageDeleted="onDeleteListChange"></ImagenesContainer>
                </template>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 d-flex flex-column flex-lg-row justify-content-between align-lg-items-center">
                <!--Todo Disable if we dont have images-->
                <div :style="{ opacity: true ? 1 : 0.5 }" class="card mb-3 mb-lg-0 me-2">
                  <div class="card-body px-2 py-0">
                    <p class="mb-0 text-center text-lg-start d-flex align-items-center">
                      <span class="me-2"> Duración por imagen</span>
                      <span class="d-flex align-items-center justify-content-end">
                        <button @click="removeTime()" :disabled="!true"
                          class="btn btn-custom-color-white rounded-circle border" :class="{
                            disabled: imagesListTime <= 4,
                          }" type="button">
                          <small>
                            <font-awesome-icon icon="minus" />
                          </small>
                        </button>
                        <span class="border rounded-2 py-0 px-2 mx-2 mw-100 text-center">{{ imagesListTime }} s</span>
                        <button @click="addTime()" :disabled="!true"
                          class="btn btn-custom-color-white rounded-circle border" :class="{
                            disabled: imagesListTime >= 60,
                          }" type="button">
                          <small>
                            <font-awesome-icon icon="plus" />
                          </small>
                        </button>
                      </span>
                    </p>
                  </div>
                </div>
                <div :style="{ opacity: tiempo_total != '00:00:00' }" class="card mb-3 mb-lg-0">
                  <div class="card-body p-2" content="Debes completar los campos <br> requeridos(*) para continuar."
                    v-tippy="{
                      placement: 'top',
                      arrow: true,
                      trigger: 'mouseenter focus',
                      followCursor: true,
                    }">
                    <p class="mb-0 text-center text-lg-start">
                      Tiempo de duración total
                      <span class="border color-main py-1 px-2 ms-1 border-round-10">
                        {{ tiempo_total }}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="flex-grow-1 d-flex align-items-center justify-content-end">
                  <button class="btn btn-custom-color-white border border-round-50 mw-100 me-2" @click="$router.go(-1)">
                    Volver
                  </button>
                  <span content="Agrega al menos 1 elemento <br> a la lista de reproducción." v-tippy="{
                      placement: 'top',
                      arrow: true,
                      trigger: !active_button ? 'mouseenter focus' : '',
                    }">
                    <button class="btn btn-custom-color-blue border-round-50 mw-100" @click="addVideoListToChannel"
                      :disabled="!active_button">
                      Guardar
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="add_modal" class="add-banner__modal">
      <CargarImagenesModal v-if="video_data.type === 'image'" @closeModal="closeModal" @imageUploaded="onImageUpload"
        @showSpinner="show_spinner = true" @hideSpinner="show_spinner = false" />
      <CargarVideoExternoModal v-if="video_data.type === 'externo'" @closeModal="closeModal"
        @videoUploaded="onVideoExternoUpload" @showSpinner="show_spinner = true" @hideSpinner="show_spinner = false" />
    </div>
    <Spinner v-show="show_spinner" />
    <LoadingProgress :value="loading_progress" v-if="show_progress" />
    <ProgressToast :stepPercent="toastStagePercent" />
  </section>
</template>

<script>
import { Toast } from "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";

import { VideoAdapter } from "../../models/VideoAdapter";
import moment from "moment";
import { isImage } from "@/utils/formats.js";
import { videoExtensions } from "@/utils/constants.js";
import { formatTime, parseTime } from "@/utils/time.js";
import { mapState, mapGetters, mapActions } from "vuex";

const Spinner = () => import("../Spinner.vue");
const ProgressToast = () => import("../ProgressToast.vue");
const VideosContainer = () => import("./VideosContainer.vue");
const LoadingProgress = () => import("../LoadingProgress.vue");
const ImagenesContainer = () => import("./ImagenesContainer.vue");
const VideosYTContainer = () => import("./VideosYTContainer.vue");
const CargarImagenesModal = () => import("./CargarImagenesModal.vue");
const CargarVideoExternoModal = () => import("./CargarVideoExternoModal.vue");

export default {
  components: {
    VideosContainer,
    VideosYTContainer,
    ImagenesContainer,
    Spinner,
    LoadingProgress,
    ProgressToast,
    CargarImagenesModal,
    CargarVideoExternoModal,
  },

  data() {
    return {
      id: this.$route.params.id,
      active_button: false,
      tiempo_total: "00:00:00",
      active_time: false,
      video_data: {
        id: 0,
        value: 0,
        type: "video",
        text: "Videos",
        if: "",
      },
      video_data_opts: [
      ],
      show_spinner: false,
      show_progress: false,
      loading_progress: 0,
      list_video: [],
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario: this.$ls.get("user").id_usuario,
      categorias: [],
      cat_selected: "",
      pagination: {
        actual_page: 1,
        page_count: 1,
        page_range: 3,
        click_handler: this.onPageChange,
        prev_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",
      },
      title: "",
      selected_videos: [],
      filesPerPage: "12",
      toastStagePercent: 0,
      loadingToast: null,
      add_modal: false,
    };
  },

  watch: {
    video_data: {
      deep: true,
      async handler() {
        this.renderList();
        return;
      },
    },
    selectedMediaList() {
      this.setImagesTimepreLoaded();
    },

    cat_selected() {
      this.getPaginatedVideoIdsFromVideos();
    },

    paginationDataForRepoVideos: {
      handler(val) {
        if (this.video_data.value == 0 || this.video_data.value == 3) {
          Object.assign(this.pagination, val);
        }
      },
      deep: true,
    },
    paginationDataForRepoVideosExterno: {
      handler(val) {
        if (this.video_data.value == 2) {
          Object.assign(this.pagination, val);
        }
      },
      deep: true,
    },
    paginationDataForVideoVideos: {
      handler(val) {
        if (this.video_data.value == 1) {
          Object.assign(this.pagination, val);
        }
      },
      deep: true,
    },
    imagesListTime() {
      this.onPlaylistChange(this.selected_videos);
    },
  },

  computed: {
    ...mapGetters("sidebar", ["userHasVideoModule"]),
    ...mapState("videoModule", ["videoVideosPagination"]),
    ...mapGetters("videoModule", [
      "videoVideosFiltered",
      "paginationDataForVideoVideos",
    ]),
    ...mapState("tvCorporativaModule", [
      "repoVideosPagination",
      "repoImagesPagination",
      "repoExternoPagination",
      "imagesListTime",
      "selectedMediaList",
    ]),
    ...mapGetters("tvCorporativaModule", [
      "repoVideosFiltered",
      "repoImagesFiltered",
      "repoVideosExternoFiltered",
      "paginationDataForRepoVideos",
      "paginationDataForRepoVideosExterno",
      "paginationDataForRepoImages",
    ]),
    fillter_opts() {
      return this.video_data_opts.filter((el) => {
        if (el.if === "") return true;
        return this.userHasVideoModule;
      });
    },
    textComputed() {
      const types = {
        video: {
          accept: ".mp4",
          button: "Cargar video",
          file_type: "1",
          modal: "Ingresa nombre del video",
          title_1: "Listado de videos",
          title_2: "un video",
        },
        externo: {
          accept: "url",
          button: "Cargar video de YouTube",
          file_type: "7",
          modal: "Ingresa nombre de la imagen",
          title_1: "Listado de videos externos",
          title_2: "un video",
        },
        image: {
          accept: ".mp4",
          button: "Cargar imagen",
          file_type: "6",
          modal: "Ingresa nombre de la imagen",
          title_1: "Listado de imágenes",
          title_2: "una imagen",
        },
      };
      return (
        types[this.video_data.type] || {
          accept: "",
          button: "",
          file_type: "",
          modal: "",
          title_1: "",
          title_2: "",
        }
      );
    },
  },

  async mounted() {
    this.show_spinner = true;
    await this.getVideoDataOpts();
    await this.getAllFilesReop();
    await Promise.all([
      this.getPaginatedVideoIdsFromRepo(),
      this.getPaginatedVideoIdsFromVideos(),
    ]);

    const toastEl = document.getElementById("progressToast");
    this.loadingToast = new Toast(toastEl, { autohide: false });
    this.show_spinner = false;
    this.onPlaylistChange(this.selected_videos);
    this.setImagesTimepreLoaded();
  },

  methods: {
    ...mapActions("tvCorporativaModule", [
      "setChannelLayoutVideos",
      "getFilesPagination",
      "getAllRepoFiles",
      "uploadFileSubscription",
      "imagesListTimeUpdate",
    ]),
    ...mapActions("videoModule", [
      "getPagination",
      "getAllVideoVideos",
      "getCategoriesAction",
      "getVideoOptions",
    ]),

    async getVideoDataOpts() {
      const res = await this.getVideoOptions()
      if (res) {
        console.log(this.video_data_opts);
        this.video_data_opts = res.map((item) => ({
          ...item,
          value: item.id
        }));
        console.log(this.video_data_opts);
        
        this.video_data = this.video_data_opts[0];
      }
    },
    async getAllFilesReop() {
      return await Promise.all([
        this.getCategories(),
        this.getAllFilesFromRepo("1"),
        this.getAllFilesFromRepo("6"),
        this.getAllFilesFromRepo("7"),
        this.getAllVideosFromVideos(),
      ]);
    },
    setImagesTimepreLoaded() {
      const imagesTime = this.selected_videos.filter(({ url_archivo }) =>
        isImage(url_archivo)
      );
      if (imagesTime.length > 0) {
        this.imagesListTimeUpdate(parseTime(imagesTime[0].tiempo_video));
      }
    },
    renderList() {
      this.cleanVideosList();
      if (this.video_data.value == 0) {
        this.list_video = this.repoVideosFiltered(0).map(
          (video) => new VideoAdapter(video)
        );
        Object.assign(this.pagination, this.paginationDataForRepoVideos);
      }
      if (this.video_data.value == 1) {
        this.list_video = this.videoVideosFiltered(0).map(
          (video) => new VideoAdapter(video)
        );
        Object.assign(this.pagination, this.paginationDataForVideoVideos);
      }
      if (this.video_data.value == 2) {
        this.list_video = this.repoVideosExternoFiltered(0).map(
          (video) => new VideoAdapter(video)
        );
        Object.assign(this.pagination, this.paginationDataForRepoVideosExterno);
      }
      if (this.video_data.value == 3) {
        this.list_video = this.repoImagesFiltered(0).map(
          (video) => new VideoAdapter(video)
        );
        Object.assign(this.pagination, this.paginationDataForRepoImages);
      }
    },
    addImageModal() {
      if (this.video_data.value == 2 || this.video_data.value == 3) this.add_modal = true;
    },
    closeModal() {
      this.add_modal = false;
    },
    async onImageUpload() {
      this.closeModal();
      await this.getAllVideosFromServer();
      await this.getAllFilesReop();
      await this.getPaginatedVideoIdsFromRepo();
      this.renderList();
      this.pagination.actual_page = 1;
    },
    async onVideoExternoUpload() {
      this.closeModal();
      await this.getAllFilesFromRepo("7");
      await this.getPaginatedVideoIdsFromRepo();
      this.renderList();
      this.pagination.actual_page = 1;
    },
    cleanVideosList() {
      while (this.list_video.length != 0) {
        this.list_video.shift();
      }
    },
    isVideo(arUrl) {
      const extension = arUrl.split(".").pop();
      return videoExtensions.has(extension);
    },
    addTime() {
      if (this.imagesListTime < 60) {
        this.imagesListTimeUpdate(this.imagesListTime + 1);
      }
    },
    removeTime() {
      if (this.imagesListTime > 4) {
        this.imagesListTimeUpdate(this.imagesListTime - 1);
      }
    },
    onPlaylistChange(selected_videos) {
      this.selected_videos = selected_videos;
      const images = selected_videos.filter((video) => {
        return !this.isVideo(
          video.url_video !== undefined ? video.url_video : video.url_archivo
        );
      });

      if (selected_videos.length >= 1) {
        this.active_time = images.length !== 0;
        this.active_button = true;
      } else if (selected_videos.length == 0) {
        this.active_time = false;
        this.active_button = false;
      }
      const times = selected_videos.map((video) => {
        if (video?.url_archivo) {
          if (!this.isVideo(video.url_archivo)) {
            return formatTime(this.imagesListTime);
          }
        }
        return video.tiempo_video;
      });
      this.tiempo_total = this.sumTotalTime(times);
    },
    /**
     * Carga y procesa un archivo de video/imagen al sistema
     * @param {Event} event Evento del input file
     */
    onFileLoad(event) {
      const fileElement = event.target;
      const file = fileElement.files[0];
      const fileType = file.type;
      const fileName = file.name;
      const newFile = {};
      newFile.name = fileName;

      const reader = new FileReader();

      if (
        (this.video_data.type == "video" && fileType !== "video/mp4") ||
        (this.video_data.type == "image" &&
          fileType !== "image/jpeg" &&
          fileType !== "image/jpg")
      ) {
        this.loading_progress = 0;
        this.toastWarn(
          `Por favor selecciona un archivo con formato válido (.${
            this.video_data.type == "video" ? "mp4" : "jpg"
          }).`
        );
        return;
      }

      if (file.size >= 380 * 1024 * 1024) {
        this.loading_progress = 0;
        this.toastWarn(
          "El medio excede los 380 MB permitidos. Por favor selecciona un medio con peso menor a 380 MB."
        );
        return;
      }

      this.show_progress = true;
      this.startBrowserVideoLoadProgress();

      reader.onload = (e) => {
        newFile.base64 = e.target.result;
        newFile.src = e.target.result.split(",")[1];
        newFile.type = e.target.result.split(";")[0].split(":")[1];
        if (this.video_data.type == "video") {
          // Obtención de la duración del video
          const video = document.createElement("video");
          video.preload = "metadata";
          video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            newFile.duration = video.duration;
            this.proceedToBackEndVideoLoadProgress();
            this.loadMedio(newFile, 2);
          };
          video.src = URL.createObjectURL(file);
        }
        if (this.video_data.type == "image") {
          newFile.duration = this.imagesListTime;
          this.proceedToBackEndVideoLoadProgress();
          this.loadMedio(newFile, 6);
        }
      };

      reader.onprogress = (data) => {
        if (data.lengthComputable) {
          this.loading_progress = parseInt(
            (data.loaded / data.total) * 100,
            10
          );
          this.toastStagePercent = parseInt(
            (data.loaded / data.total) * 100,
            10
          );
        }
      };

      reader.onloadend = () => {
        this.loading_progress = 0;
        this.toastStagePercent = 0;
        this.show_progress = false;
        event.target.value = "";
      };

      reader.readAsDataURL(file);
    },

    /**
     * Verifica el estado de la carga del video en el servidor
     * @param {string} url URL del video cargado
     * @async
     */
    async verifyVideoUploadStatus(url) {
      try {
        const res = await fetch(url, { method: "HEAD" });
        const status = res.status;
        if (status !== 200) {
          this.toastVideoURLError();
        }
      } catch (error) {
        this.toastConnectionError();
        console.error("Error:", error);
      }
    },

    /**
     * Carga de video
     * @param {Object} medio Objeto con la información del video
     * @param {Number} type 2: videoMain 6: ImagenMain
     */
    async loadMedio(medio, type) {
      const data = {
        id_empresa: this.id_empresa,
        id_usuario: this.id_usuario,
        tipo_archivo: type, //2: videoMain 6: ImagenMain 7: videoYoutube,
        file: medio,
      };

      try {
        const fileObservable = await this.uploadFileSubscription(data);
        const subscription = fileObservable.subscribe(async (data) => {
          const uploadProgressData = data.data.saveFileRepo;
          if (uploadProgressData && uploadProgressData.percent) {
            this.toastStagePercent = +uploadProgressData.percent;
          }
          if (
            uploadProgressData &&
            uploadProgressData.percent === "100" &&
            uploadProgressData.url_archivo
          ) {
            await this.verifyVideoUploadStatus(uploadProgressData.url_archivo);
            this.toastVideoUploadSuccess();
            await this.getAllVideosFromServer();
            await this.getAllFilesReop();
            await Promise.all([
              this.getPaginatedVideoIdsFromRepo(),
              this.getPaginatedVideoIdsFromVideos(),
            ]);
            setTimeout(() => {
              subscription.unsubscribe();
              this.closeVideoProgressToast();
            }, 1000);
          }
        });
      } catch (error) {
        this.toastVideoUploadError();
      }
    },

    /**
     * Agrega los videos/imágenes seleccionados al canal
     * Convierte tiempos y formatea lista antes de guardar
     */
    addVideoListToChannel() {
      const formatList = this.selected_videos.map((video) => {
        if (video?.url_archivo) {
          if (!this.isVideo(video.url_archivo)) {
            video.tiempo_video = formatTime(this.imagesListTime);
          }
        }
        return video;
      });
      this.setChannelLayoutVideos({
        videos: formatList,
        tiempo_total: this.tiempo_total,
      });
      this.toastVideoPlaylistAddSuccess();
      this.$router.push({
        name: "CanalCorporativo-addContenido",
        params: { id: this.$route.params.id },
      });
    },

    async getCategories() {
      const payload = {
        idEmpresa: this.id_empresa,
        plataforma: "2",
      };

      this.categorias = await this.getCategoriesAction(payload);

      //FF
      this.categorias.unshift({
        __typename: "CategoriasVideos",
        id_categoria: "0",
        nombre_categoria: "Todas",
      });

      if (this.categorias === null) {
        this.toastGetVideosError();
      }
    },

    onPageChange(page) {
      this.show_spinner = true;
      this.pagination.actual_page = page;
      if (
        this.video_data.value == 0 ||
        this.video_data.value == 2 ||
        this.video_data.value == 3
      ) {
        this.getRepoMediaForPage(page);
      } else if (this.video_data.value == 1) {
        this.getVideoVideosForPage(page);
      }
      this.show_spinner = false;
    },

    async getAllVideosFromServer() {
      await this.getAllFilesFromRepo();
      await this.getPaginatedVideoIdsFromRepo();

      if (this.video_data.value == 0) {
        this.list_video = this.repoImagesFiltered(0).map(
          (video) => new VideoAdapter(video)
        );
      }
      if (this.video_data.value == 2) {
        this.list_video = this.repoVideosExternoFiltered(0).map(
          (video) => new VideoAdapter(video)
        );
      }
      if (this.video_data.value == 3) {
        this.list_video = this.repoImagesFiltered(0).map(
          (video) => new VideoAdapter(video)
        );
      }

      Object.assign(this.pagination, this.paginationDataForRepoImages);
    },

    async onDeleteListChange() {
      if (
        this.video_data.value === 0 ||
        this.video_data.value === 2 ||
        this.video_data.value === 3
      ) {
        await this.getPaginatedVideoIdsFromRepo();
      }
      if (this.video_data.value === 1) {
        await this.getPaginatedVideoIdsFromVideos();
      }

      this.renderList();
      this.pagination.actual_page = 1;
    },

    async getAllVideosFromVideos() {
      const data = {
        idEmpresa: this.id_empresa,
        limite: this.filesPerPage,
        idCategoria:
          this.cat_selected.nombre_categoria == "Todas" ||
          this.cat_selected == ""
            ? "0"
            : this.cat_selected.id_categoria,
        fechaInicio: "",
        fechaFinal: "",
      };

      const res = await this.getAllVideoVideos(data);
      if (!res) {
        this.toastGetVideosError();
      }
    },

    async getAllFilesFromRepo(type = "") {
      const data = {
        idEmpresa: this.id_empresa,
        limite: this.filesPerPage,
        tipoArchivo: type == "" ? "1" : type,
      };

      const res = await this.getAllRepoFiles(data);

      if (!res) {
        this.toastGetVideosError();
      }
    },

    /**
     * Obtiene los videos paginados según filtros seleccionados
     * @param {string} nombre_video Nombre para filtrar videos
     * @async
     */
    async getPaginatedVideoIdsFromVideos(nombre_video = "") {
      this.show_spinner = true;
      const payload = {
        idEmpresa: this.id_empresa,
        nombre: nombre_video,
        fechaInicio: "",
        fechaFinal: "",
        idCategoria:
          this.cat_selected.nombre_categoria == "Todas" ||
          this.cat_selected == ""
            ? "0"
            : this.cat_selected.id_categoria,
        limite: this.filesPerPage,
      };
      await this.getPagination(payload);
      if (this.videoVideosPagination === null) {
        this.toastGetVideosError();
      } else {
        if (this.videoVideosPagination.length > 0) {
          this.getVideoVideosForPage(1);
        }
      }
      this.show_spinner = false;
    },

    /**
     * Obtiene los videos paginados según filtros seleccionados
     * @param {string} nombre_video Nombre para filtrar videos
     * @async
     */
    async getPaginatedVideoIdsFromRepo(nombre_video = "") {
      this.show_spinner = true;
      const data = {
        idEmpresa: this.id_empresa,
        tipoArchivo: this.textComputed.file_type,
        limite: this.filesPerPage,
        nombreArchivo: nombre_video,
      };
      await this.getFilesPagination(data);

      // video
      if (
        this.video_data.type == "video" &&
        this.repoVideosPagination === null
      ) {
        this.toastGetVideosError();
      }
      if (
        this.video_data.type == "video" &&
        this.repoVideosPagination !== null
      ) {
        if (this.repoVideosPagination.length > 0) {
          this.getRepoMediaForPage(1);
        }
      }

      //externo
      if (
        this.video_data.type == "externo" &&
        this.repoExternoPagination === null
      ) {
        this.toastGetVideosError();
      }
      if (
        this.video_data.type == "externo" &&
        this.repoExternoPagination !== null
      ) {
        if (this.repoExternoPagination.length > 0) {
          this.getRepoMediaForPage(1);
        }
      }
      // img
      if (
        this.video_data.type == "image" &&
        this.repoImagesPagination === null
      ) {
        this.toastGetVideosError();
      }
      if (
        this.video_data.type == "image" &&
        this.repoImagesPagination !== null
      ) {
        if (this.repoImagesPagination.length > 0) {
          this.getRepoMediaForPage(1);
        }
      }

      this.show_spinner = false;
    },

    getVideoVideosForPage(page) {
      if (this.video_data.value == 1)
        this.list_video = this.videoVideosFiltered(page - 1).map(
          (video) => new VideoAdapter(video)
        );
    },

    async getRepoMediaForPage(page) {
      if (this.video_data.value == 0) {
        this.list_video = this.repoVideosFiltered(page - 1).map(
          (video) => new VideoAdapter(video)
        );
      }
      if (this.video_data.value == 2) {
        this.list_video = this.repoVideosExternoFiltered(page - 1).map(
          (video) => new VideoAdapter(video)
        );
      }
      if (this.video_data.value == 3) {
        this.list_video = this.repoImagesFiltered(page - 1).map(
          (video) => new VideoAdapter(video)
        );
      }
    },

    onVideoSearch() {
      if (this.video_data.value == 0 || this.video_data.value == 3) {
        this.getPaginatedVideoIdsFromRepo(this.title);
      } else if (this.video_data.value == 1) {
        this.getPaginatedVideoIdsFromVideos(this.title);
      }
    },

    /**
     * Calcula tiempo total de reproducción de la lista
     * @param {Array} tiempos Array con tiempos en formato HH:mm:ss
     * @returns {string} Tiempo total en formato HH:mm:ss
     */
    sumTotalTime(tiempos) {
      const sum = tiempos.reduce(
        (acc, time) => acc.add(moment.duration(time)),
        moment.duration()
      );
      return moment.utc(sum.as("milliseconds")).format("HH:mm:ss");
    },

    startBrowserVideoLoadProgress() {
      this.loadingToast.show();
    },

    proceedToBackEndVideoLoadProgress() {
      this.toastStagePercent = 0;
    },

    closeVideoProgressToast() {
      this.loadingToast.hide();
      this.toastStagePercent = 0;
    },

    toastVideoPlaylistAddSuccess() {
      this.$toast.open({
        message: "Tu lista de video ha sido agregada correctamente.",
        type: "success",
        duration: 5000,
        position: "top-right",
      });
    },
    toastWarn(txt = "") {
      this.$toast.open({
        message: txt,
        type: "warning",
        duration: 5000,
        position: "top-right",
      });
    },
    toastVideoUploadMaxSizeError() {
      this.$toast.open({
        message:
          "El archivo excede los 400 MB permitidos. Por favor selecciona un video o imagen con peso menor a 400 MB.",
        type: "warning",
        duration: 5000,
        position: "top-right",
      });
    },
    toastVideoUploadError() {
      this.$toast.open({
        message: "Falla al momento de cargar el archivo.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    },
    toastVideoUploadSuccess() {
      this.$toast.open({
        message: "El archivo ha sido cargado exitosamente.",
        type: "success",
        duration: 6000,
        position: "top-right",
      });
    },
    toastGetVideosError() {
      this.$toast.open({
        message:
          "Error en la obtención de datos. Por favor intenta nuevamente recargando la página.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    },
    toastVideoURLError() {
      this.$toast.open({
        message:
          "El video se ha cargado con problemas. Por favor revisa el archivo cargado o intenta nuevamente.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    },
    toastConnectionError() {
      this.$toast.open({
        message:
          "Error en la conexión. Por favor revisa tu conexión a internet.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    },
  },
};
</script>
